import React from "react"
import { graphql } from "gatsby"
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TeamFigure from "../components/team/figure"

type Props = {
  data: {
    crew: {
      edges: Array<{
        node: {
          image?: Required<FileNode>
          name: string
          role: string
          links: {
            twitter: string
            instagram: string
            linkedin: string
          }
        }
      }>
    }
    developers: {
      edges: Array<{
        node: {
          image?: Required<FileNode>
          name: string
          role: string
          links: {
            twitter: string
            instagram: string
            linkedin: string
          }
        }
      }>
    }
    artists: {
      edges: Array<{
        node: {
          image?: Required<FileNode>
          name: string
          links: {
            twitter: string
            instagram: string
            discord: string
            website: string
          }
        }
      }>
    }
    fashion: {
      edges: Array<{
        node: {
          image?: Required<FileNode>
          name: string
          links: {
            twitter: string
            instagram: string
            discord: string
            website: string
          }
        }
      }>
    }
    partner: {
      edges: Array<{
        node: {
          url: string
          name: string
        }
      }>
    }
  }
}

const TeamPage: React.FC<Props> = ({ data }) => {
  return (
    <Layout bgColor="black">
      <SEO title="OREMOB Team" />

      <div className="flex flex-col gap-24">
        <h1 className="mb-0 text-center font-gravity-compressed text-[39.5vw] uppercase leading-[0.75em]">
          OREMOB Crew
        </h1>
        <div className="flex flex-col gap-6">
          <h2 className="container mt-8 text-center font-gravity-compressed text-[60px] uppercase leading-[0.75em] lg:text-[180px]">
            HQ
          </h2>
          <div className="px-4 2xl:container">
            <ol className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
              {data.crew.edges.map(({ node }) =>
                node.image ? (
                  <li key={node.name}>
                    <TeamFigure
                      image={node.image}
                      name={node.name}
                      role={node.role}
                      links={node.links}
                    />
                  </li>
                ) : null
              )}
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <h2 className="container mt-8 text-center font-gravity-compressed text-[60px] uppercase leading-[0.75em] lg:text-[180px]">
            Developers
          </h2>
          <div className="px-4 2xl:container">
            <ol className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
              {data.developers.edges.map(({ node }) =>
                node.image ? (
                  <li key={node.name}>
                    <TeamFigure
                      image={node.image}
                      name={node.name}
                      role={node.role}
                      links={node.links}
                    />
                  </li>
                ) : null
              )}
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <h2 className="container mt-8 text-center font-gravity-compressed text-[60px] uppercase leading-[0.75em] lg:text-[180px]">
            ARTIST COLLABS
          </h2>
          <div className="px-4 2xl:container">
            <ol className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
              {data.artists.edges.map(({ node }) =>
                node.image ? (
                  <li key={node.name}>
                    <TeamFigure
                      image={node.image}
                      name={node.name}
                      links={node.links}
                    />
                  </li>
                ) : null
              )}
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <h2 className="container mt-8 text-center font-gravity-compressed text-[60px] uppercase leading-[0.75em] lg:text-[180px]">
            Fashion COLLABS
          </h2>
          <div className="px-4 2xl:container">
            <ol className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
              {data.fashion.edges.map(({ node }) =>
                node.image ? (
                  <li key={node.name}>
                    <TeamFigure
                      image={node.image}
                      name={node.name}
                      links={node.links}
                    />
                  </li>
                ) : null
              )}
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <h2 className="container mt-8 text-center font-gravity-compressed text-[60px] uppercase leading-[0.75em] lg:text-[180px]">
            Partner Companies
          </h2>
          <div className="px-4 2xl:container">
            <ol className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
              {data.partner.edges.map(({ node }) => (
                <li
                  key={node.name}
                  className="flex aspect-[4/5] bg-white font-gravity-compressed text-[60px] uppercase leading-[0.75] text-black transition-colors duration-150 ease-in-out hover:bg-red hover:text-white lg:text-[96px]"
                >
                  <a
                    href={node.url}
                    className="flex h-full w-full items-center justify-center whitespace-pre-line p-8 text-center"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {node.name}
                  </a>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TeamPage

export const query = graphql`
  {
    crew: allCrewYaml(
      sort: { fields: sort_order, order: ASC }
      filter: { visible: { eq: true } }
    ) {
      edges {
        node {
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                breakpoints: [1500]
                layout: FULL_WIDTH
              )
            }
          }
          name
          role
          links {
            instagram
            twitter
            linkedin
          }
        }
      }
    }
    developers: allDevelopersYaml(
      sort: { fields: sort_order, order: ASC }
      filter: { visible: { eq: true } }
    ) {
      edges {
        node {
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                breakpoints: [1500]
                layout: FULL_WIDTH
              )
            }
          }
          name
          role
          links {
            instagram
            twitter
            linkedin
          }
        }
      }
    }
    artists: allArtistsYaml(
      sort: { fields: sort_order, order: ASC }
      filter: { visible: { eq: true } }
    ) {
      edges {
        node {
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                breakpoints: [1500]
                layout: FULL_WIDTH
              )
            }
          }
          name
          links {
            instagram
            twitter
            discord
            website
          }
        }
      }
    }
    fashion: allFashionYaml(
      sort: { fields: sort_order, order: ASC }
      filter: { visible: { eq: true } }
    ) {
      edges {
        node {
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                breakpoints: [1500]
                layout: FULL_WIDTH
              )
            }
          }
          name
          links {
            instagram
            twitter
            discord
            website
          }
        }
      }
    }
    partner: allPartnerYaml(
      sort: { fields: sort_order, order: ASC }
      filter: { visible: { eq: true } }
    ) {
      edges {
        node {
          url
          name
        }
      }
    }
  }
`
