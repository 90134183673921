import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks"
import TwitterIcon from "../../images/svg/team/twitter.svg"
import InstagramIcon from "../../images/svg/team/instagram.svg"
import LinkedinIcon from "../../images/svg/team/linkedin.svg"
import DiscordIcon from "../../images/svg/team/discord.svg"
import WebsiteIcon from "../../images/svg/team/website.svg"

type FigureProps = {
  image?: Required<FileNode>
  name: string
  role?: string
  links: {
    twitter?: string
    instagram?: string
    linkedin?: string
    website?: string
    discord?: string
  }
}

const TeamFigure: React.FC<FigureProps> = ({ image, name, role, links }) => {
  return (
    <figure className="flex flex-col gap-2">
      {image ? <GatsbyImage
        image={image.childImageSharp.gatsbyImageData}
        alt={name}
        className="aspect-square"
      /> : 
      <div className="aspect-square bg-white flex justify-center items-center font-gravity-wide text-black uppercase text-3xl"> Coming Soon</div>}
      <figcaption className="flex flex-row justify-between">
        <ol className="flex flex-row gap-[8px]">
          {links.twitter && links.twitter.length ? (
            <li>
              <a
                href={links.twitter}
                target="_blank"
                rel="noreferrer noopener"
                title="Twitter"
                className="flex justify-center items-center w-[32px] h-[32px] bg-white"
              >
                <TwitterIcon className="h-[24px] w-[24px]" />
              </a>
            </li>
          ) : null}
          {links.instagram && links.instagram.length ? (
            <li>
              <a
                href={links.instagram}
                target="_blank"
                rel="noreferrer noopener"
                title="Instagram"
                className="flex justify-center items-center w-[32px] h-[32px] bg-white"
              >
                <InstagramIcon className="h-[24px] w-[24px]" />
              </a>
            </li>
          ) : null}
          {links.linkedin && links.linkedin.length ? (
            <li>
              <a
                href={links.linkedin}
                target="_blank"
                rel="noreferrer noopener"
                title="LinkedIn"
                className="flex justify-center items-center w-[32px] h-[32px] bg-white"
              >
                <LinkedinIcon className="h-[24px] w-[24px]" />
              </a>
            </li>
          ) : null}
          {links.discord && links.discord.length ? (
            <li>
              <a
                href={links.discord}
                target="_blank"
                rel="noreferrer noopener"
                title="Discord"
                className="flex justify-center items-center w-[32px] h-[32px] bg-white"
              >
                <DiscordIcon className="h-[24px] w-[24px]" />
              </a>
            </li>
          ) : null}
          {links.website && links.website.length ? (
            <li>
              <a
                href={links.website}
                target="_blank"
                rel="noreferrer noopener"
                title="Website"
                className="flex justify-center items-center w-[32px] h-[32px] bg-white"
              >
                <WebsiteIcon className="h-[24px] w-[24px]" />
              </a>
            </li>
          ) : null}
        </ol>
        <div className="flex flex-col text-right font-gravity-wide uppercase">
          <span className="leading-none">{name}</span>
          {role && <span className="leading-none text-white/60">{role}</span>}
        </div>
      </figcaption>
    </figure>
  )
}

export default TeamFigure
